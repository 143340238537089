const env = import.meta.env;

export const requireEnvironmentVariable = (envStr: string): string => {
  const envVar = env[envStr];
  if (typeof envVar === 'undefined')
    throw new Error(`${envStr} required but not found`);
  if (typeof envVar === 'boolean') return `${envVar}`;
  return envVar;
};

export const WALLETCONNECT_PROJECT_ID = requireEnvironmentVariable(
  'VITE_WALLETCONNECT_PROJECT_ID'
);

export const WORKER_MAIN_URL = requireEnvironmentVariable(
  'VITE_WORKER_MAIN_URL'
);
export const WORKER_MAIN_WS_URL = requireEnvironmentVariable(
  'VITE_WORKER_MAIN_WS_URL'
);
export const WEBAUTHN_RPID = requireEnvironmentVariable('VITE_WEBAUTHN_RPID');

export const EVM_CHAIN_ENV = env['VITE_EVM_ENV'];

// RPC URLS
export const ETHEREUM_RPC = env['VITE_ETH_RPC'] || undefined;

export const POSTHOG_KEY = env['VITE_POSTHOG_KEY'];
export const POSTHOG_PROJECT_ID = env['VITE_POSTHOG_PROJECT_ID'];

export enum Environments {
  DEV = 'dev', //dev
  TESTING = 'test', //test
  STAGING = 'staging',
  PRODUCTION = 'prod', //prod
}
export const INFINEX_ENVIRONMENT = requireEnvironmentVariable(
  'VITE_INFINEX_ENVIRONMENT'
) as Environments;

export const PUBLIC_WEBSITE_URL = requireEnvironmentVariable(
  'VITE_PUBLIC_WEBSITE_URL'
);

export const ORIGIN_URL = requireEnvironmentVariable('VITE_ORIGIN_URL');
