import { AriaLabelingProps, DOMProps } from '@react-types/shared';
import { type ReactNode, createContext, forwardRef } from 'react';

import { type StyleProps, cn } from '../../utils';

export type SurfaceProps = {
  /** The contents of the surface. */
  children?: ReactNode;
  /**
   * The underlying HTML element to render.
   * @default 'div'
   */
  elementType?: 'aside' | 'div' | 'footer' | 'header' | 'section' | 'nav';
  /**
   * The level of the surface, which determines the background color.
   *
   * @default 1
   */
  level?: 1 | 2;
} & AriaLabelingProps &
  DOMProps &
  StyleProps;

export const Surface = forwardRef<HTMLDivElement, SurfaceProps>(
  function Surface(props, ref) {
    const {
      children,
      className,
      elementType: Element = 'div',
      level = 1,
      ...otherProps
    } = props;

    return (
      <Element
        ref={ref}
        className={cn(
          {
            'bg-surfaceOne': level === 1,
            'bg-surfaceTwo': level === 2,
          },
          className
        )}
        {...otherProps}
      >
        <SurfaceContext.Provider value={level}>
          {children}
        </SurfaceContext.Provider>
      </Element>
    );
  }
);

export const SurfaceContext = createContext(0);
