import type { DOMProps } from '@react-types/shared';
import { type ReactNode, forwardRef } from 'react';

import { type StyleProps, cn } from '../../utils';

export type EmptySectionProps = {
  /** The content of the empty section. */
  children: ReactNode;
} & DOMProps &
  StyleProps;

/** Represents a subsection of grouped content. */
export const EmptySection = forwardRef<HTMLDivElement, EmptySectionProps>(
  function EmptySection(props, ref) {
    const { className, ...otherProps } = props;

    return (
      <div
        ref={ref}
        className={cn(
          'text-subtle body-sm-normal min-h-16 content-center rounded-lg border border-dashed p-4 text-center',
          className
        )}
        {...otherProps}
      />
    );
  }
);
