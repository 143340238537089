import { filterDOMProps } from '@react-aria/utils';
import type { DOMProps } from '@react-types/shared';
import { forwardRef } from 'react';

import { type StyleProps, cn } from '../../utils';

import { EMBLEM_DIMENSIONS, NETWORK_METADATA } from './constants';

export type NetworkEmblemProps = {
  /**
   * When true, the emblem is decorative (usually because of adjacent text) and
   * will not be visible to screen readers.
   *
   * @default false
   */
  isDecorative?: boolean;
  /** The network glyph to display. */
  of: keyof typeof NETWORK_METADATA;
  /**
   * The size of the emblem.
   *
   * @default medium
   */
  size?: keyof typeof EMBLEM_DIMENSIONS;
} & DOMProps &
  StyleProps;

/**
 * A network emblem is a symbolic image that serves as a graphic representation
 * of a network, like Ethereum or Solana.
 */
export const NetworkEmblem = forwardRef<HTMLDivElement, NetworkEmblemProps>(
  function NetworkEmblem(props, ref) {
    const {
      className,
      isDecorative,
      size = 'medium',
      style,
      ...otherProps
    } = props;
    const network = NETWORK_METADATA[props.of];

    return (
      <div
        {...filterDOMProps(otherProps)}
        ref={ref}
        role={isDecorative ? 'presentation' : 'img'}
        aria-label={isDecorative ? undefined : network.name}
        style={style}
        className={cn(
          'overflow-clip',
          '[&_svg]:size-full',
          {
            'size-4 rounded-sm': size === 'xsmall', // 16px
            'size-6 rounded': size === 'small', // 24px
            'size-8 rounded-md': size === 'medium', // 32px
            'size-10 rounded-lg': size === 'large', // 40px
            'size-14 rounded-lg': size === 'xlarge', // 56px
          },
          className
        )}
      >
        {network.glyph}
      </div>
    );
  }
);
