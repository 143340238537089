import { isIOS } from '@react-aria/utils';
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import {
  type ModalOverlayProps,
  composeRenderProps,
  ModalOverlay,
  Modal as RACModal,
} from 'react-aria-components';

import { type LiteralVariantProps, cn } from '../../utils';

// Definitive "width" for sizes so dialogs without sufficient content to fill
// the horizontal space don’t collapse.
const modalVariants = cva(
  'bg-canvas max-w-[calc(100vw_-_var(--viewport-gutter))] max-h-[var(--modal-height)]',
  {
    variants: {
      size: {
        small: 'w-[28rem] border border-subtle rounded-xl shadow-xl',
        medium: 'w-[36rem] border border-subtle rounded-xl shadow-xl',
        large: 'w-[48rem] border border-subtle rounded-xl shadow-xl',
        fullscreen: 'w-screen h-full',
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  }
);

type ModalVariant = LiteralVariantProps<typeof modalVariants>;
export type ModalProps = {
  /**
   * The size of the modal.
   * @default 'medium'
   */
  size?: ModalVariant['size'];
} & ModalOverlayProps;

/** @private Consumed internally by the `Dialog` component. */
export function Modal(props: ModalProps) {
  const { isDismissable, isKeyboardDismissDisabled, size } = props;

  const { forcedHeight } = useIosInputHacks();

  return (
    <ModalOverlay
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      className={cn(
        'z-dialog bg-blanket fixed inset-0 grid place-items-center backdrop-blur-sm',
        'entering:animate-in entering:fade-in entering:duration-300 entering:ease-out',
        'exiting:animate-out exiting:fade-out exiting:duration-200 exiting:ease-in',
        {
          '[--viewport-gutter:0px]': size === 'fullscreen',
          '[--viewport-gutter:1rem] sm:[--viewport-gutter:2rem]':
            size !== 'fullscreen',
        }
      )}
      style={{
        // NOTE: The "visual-viewport-height" CSS var is set by RAC.
        // @ts-expect-error CSS vars are valid properties
        '--viewport-height': forcedHeight
          ? '100dvh'
          : 'var(--visual-viewport-height)',
        '--modal-height': `calc(var(--viewport-height) - var(--viewport-gutter))`,
        height: `var(--viewport-height)`,
      }}
    >
      <RACModal
        className={cn(
          {
            'entering:animate-in entering:slide-in-from-bottom-4 entering:duration-300 entering:ease-out':
              size !== 'fullscreen',
            'exiting:animate-out exiting:slide-out-to-bottom-2 exiting:duration-200 exiting:ease-in':
              size !== 'fullscreen',
          },
          modalVariants({ size })
        )}
      >
        {composeRenderProps(props.children, (children, modal) => {
          if (modal.isEntering || modal.isExiting || modal.state.isOpen) {
            return children;
          }

          return null;
        })}
      </RACModal>
    </ModalOverlay>
  );
}

// Utils
// -----------------------------------------------------------------------------

function useIosInputHacks() {
  const [forcedHeight, setForcedHeight] = useState(false);

  useEffect(() => {
    if (!isIOS()) {
      return;
    }

    let timeout: NodeJS.Timeout;

    const onFocusIn = ({ relatedTarget, target }: FocusEvent) => {
      if (isKeyboardInput(target)) {
        // Ignore cases (e.g. tabbing in or out of a page) where the related
        // target is null.
        if (relatedTarget == null) {
          return;
        }

        // When an input element gets focused, mobile Safari tries to put it in
        // the center by scrolling (and zooming).
        //
        // However, it doesn’t scroll when the input has an opacity of 0 or is
        // completely clipped. We can exploit this behaviour to avoid some of
        // the scroll jumpiness.
        target.style.opacity = '0';
        setTimeout(() => (target.style.opacity = '1'), 50);

        // Remove the forced height when a keyboard-enabled input is focused,
        // and cancel any pending timeouts to avoid unnecessary height changes
        // where there’s multiple focus events during the cooldown period.
        clearTimeout(timeout);
        setForcedHeight(false);
      }
    };
    const onFocusOut = ({ target }: FocusEvent) => {
      if (isKeyboardInput(target)) {
        setForcedHeight(true);

        timeout = setTimeout(() => {
          setForcedHeight(false);
          // Roughly how long it takes to get visual viewport height after the
          // keyboard is dismissed, plus a little extra for safety.
          //
          // Would love a better solution, but can't think of anything that doesn't
          // involve the `VirtualKeyboard` API, which would make all this
          // unnecessary anyway…
        }, 1000);
      }
    };

    document.addEventListener('focusin', onFocusIn);
    document.addEventListener('focusout', onFocusOut);

    return () => {
      document.removeEventListener('focusin', onFocusIn);
      document.removeEventListener('focusout', onFocusOut);

      clearTimeout(timeout);
    };
  }, []);

  return { forcedHeight };
}

const INPUTS_WITH_KEYBOARD = new Set([
  'date',
  'datetime-local',
  'email',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'time',
  'url',
]);
function isKeyboardInput(
  target: EventTarget | null
): target is HTMLInputElement {
  if (!(target instanceof HTMLElement)) {
    return false;
  }

  const element = target as HTMLInputElement;

  if (element.tagName === 'TEXTAREA') {
    return true;
  }
  if (element.tagName !== 'INPUT') {
    return false;
  }

  return INPUTS_WITH_KEYBOARD.has(element.type);
}
