import { filterDOMProps } from '@react-aria/utils';
import type { DOMProps } from '@react-types/shared';
import { Fragment, forwardRef, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { type StyleProps, cn } from '../../utils';

import { Image } from '../content';

import {
  ACTIVITY_METADATA,
  EMBLEM_DIMENSIONS,
  NETWORK_METADATA,
  NFT_FALLBACK_URL,
  TOKEN_FALLBACK_URL,
} from './constants';
import { EmblemGroupContext } from './EmblemGroup';

type ActivityDiscriminant =
  | {
      /** The activity associated with the emblem, if any. */
      activity?: Exclude<keyof typeof ACTIVITY_METADATA, 'swidge'>;
    }
  | {
      /** The activity associated with the emblem, if any. */
      activity: 'swidge';
      /** The swidge "from" asset image source URL. */
      fromSrc: string;
    };

export type EmblemProps = {
  /** The image source URL. If not provided, a fallback image will be displayed. */
  src?: string;
  /**
   * Provide `alt` text for screen readers. Set `alt=""` to indicate that the
   * image is decorative or redundant with displayed text and should not be
   * announced by screen readers.
   */
  alt?: string;
  /**
   * The size of the emblem.
   *
   * @default 'medium'
   */
  size?: keyof typeof EMBLEM_DIMENSIONS;
  /** The network associated with the emblem, if any. */
  network?: keyof typeof NETWORK_METADATA;
  /**
   * The asset variant influences the shape of the emblem, indicating the type
   * of asset represented at-a-glance.
   *
   * @default 'token'
   */
  asset?: 'token' | 'nft' | 'nft-collection';
} & DOMProps &
  StyleProps &
  ActivityDiscriminant;

// recycled for all sizes
const SWIDGE_MASK_ID = 'emblem-swidge-mask';

/**
 * An emblem is a symbolic image that serves as a graphic representation of an
 * asset, like a token or NFT.
 */
export const Emblem = forwardRef<HTMLDivElement, EmblemProps>(
  function Emblem(props, ref) {
    if (!masksAvailable) {
      throw new Error(
        'To use the `Emblem` component, you must render `EmblemMasks` at the root of the component tree.'
      );
    }

    const groupCtx = useContext(EmblemGroupContext);

    const {
      activity,
      alt,
      asset = 'token',
      className,
      network,
      size = groupCtx?.size ?? 'medium',
      src = asset === 'token' ? TOKEN_FALLBACK_URL : NFT_FALLBACK_URL,
      style,
      ...otherProps
    } = props;

    const dimensions = EMBLEM_DIMENSIONS[size];
    const isNft = asset === 'nft' || asset === 'nft-collection';

    if (groupCtx && activity) {
      console.warn(`Emblems within a group don't support "activity".`);
    }
    if (groupCtx && network) {
      console.warn(`Emblems within a group don't support "network".`);
    }
    if (activity && network) {
      console.warn(`Emblems support "activity" or "network", not both.`);
    }

    const altText = useMemo(() => {
      if (alt) {
        if (activity) {
          return `${alt} (${ACTIVITY_METADATA[activity].name})`;
        }
        if (network) {
          return `${alt} (${NETWORK_METADATA[network].name})`;
        }
      }

      return alt;
    }, [activity, alt, network]);

    const adornment = useMemo(() => {
      if (groupCtx) return null;

      if (activity) {
        return <ActivityAdornment activity={activity} size={size} />;
      }

      if (network) {
        return <NetworkAdornment network={network} size={size} />;
      }
    }, [activity, network, size, groupCtx]);

    const maskId = useMemo(() => {
      if (activity) return dimensions.activityMaskId;
      if (network) return dimensions.networkMaskId;
    }, [activity, network, dimensions]);

    return (
      <div
        {...filterDOMProps(otherProps)}
        ref={ref}
        className={cn('relative size-[var(--size)] select-none', className)}
        style={{
          // @ts-expect-error — vars are valid CSS properties
          '--size': `${dimensions.size}px`,
          '--swidge-size-to': 'calc(var(--size) * 0.86)',
          '--swidge-size-from': 'calc(var(--swidge-size-to) * 0.55)',
          '--radius': isNft ? `${dimensions.nftRadius}px` : '9999px',
          ...style,
        }}
      >
        {/* the "fold/slide out" album cover effect to indicate an NFT collection */}
        {asset === 'nft-collection' && (
          <span
            className={cn(
              'absolute inset-0 rounded-[var(--radius)] bg-[CanvasText] opacity-20',
              'origin-bottom-right rotate-12'
            )}
          />
        )}

        {/* the "from" asset, for swidge */}
        {activity === 'swidge' && (
          <Image
            src={props.fromSrc}
            alt=""
            className={cn(
              'absolute bottom-0 left-0 size-[var(--swidge-size-from)] rounded-full'
            )}
            style={{ mask: `url(#${SWIDGE_MASK_ID})`, maskMode: 'alpha' }}
          />
        )}

        {/* the main asset (for swidge this is the "to" portion) */}
        <div
          className="size-full"
          style={{
            mask: maskId && `url(#${maskId})`,
            maskMode: 'alpha',
            // @ts-expect-error — vars are valid CSS properties
            '--source-size':
              activity === 'swidge' ? 'var(--swidge-size-to)' : '100%',
          }}
        >
          <Image
            src={src}
            alt={altText}
            className={cn(
              'absolute right-0 top-0 size-[var(--source-size)] rounded-[var(--radius)]'
            )}
          />
        </div>

        {adornment}
      </div>
    );
  }
);

// Adornments
// ----------------------------------------------------------------------------

function ActivityAdornment(
  props: Required<Pick<EmblemProps, 'activity' | 'size'>>
) {
  const dimensions = EMBLEM_DIMENSIONS[props.size];

  return (
    <div
      className={cn(
        'bg-canvasInverted text-onEmphasisInverse absolute overflow-clip',
        '[&_svg]:size-full'
      )}
      style={{
        borderRadius: dimensions.activityRect.radius,
        top: dimensions.activityRect.top,
        left: dimensions.activityRect.left,
        height: dimensions.activityRect.height,
        width: dimensions.activityRect.width,
      }}
    >
      {ACTIVITY_METADATA[props.activity].glyph}
    </div>
  );
}

function NetworkAdornment(
  props: Required<Pick<EmblemProps, 'network' | 'size'>>
) {
  const dimensions = EMBLEM_DIMENSIONS[props.size];

  return (
    <div
      className={cn('absolute overflow-clip', '[&_svg]:size-full')}
      style={{
        borderRadius: dimensions.networkRect.radius,
        top: dimensions.networkRect.top,
        left: dimensions.networkRect.left,
        height: dimensions.networkRect.height,
        width: dimensions.networkRect.width,
      }}
    >
      {NETWORK_METADATA[props.network].glyph}
    </div>
  );
}

// Masks
// ----------------------------------------------------------------------------

let masksAvailable = false;

function SvgMasks() {
  masksAvailable = true;

  return (
    <svg
      aria-hidden
      focusable="false"
      tabIndex={-1}
      // The `viewBox` is mostly irrelevant. CSS mask only looks at the path
      // data, it just needs to be large enough to contain the image.
      viewBox="0 0 56 56"
      // Modified "visually hidden" styles so the element's dimensions don't
      // affect document flow. Match the width/height/offset to the viewBox so
      // things work in Safari.
      style={{
        position: 'absolute',
        width: 56,
        height: 56,
        padding: 0,
        bottom: -56,
        left: -56,
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        borderWidth: 0,
        pointerEvents: 'none',
      }}
    >
      {Object.entries(EMBLEM_DIMENSIONS).map(([size, dimensions]) => {
        const { activityRect, networkRect } = dimensions;

        return (
          <Fragment key={size}>
            <mask id={dimensions.networkMaskId}>
              <rect
                width="100%"
                height="100%"
                mask={`url(#${luminanceMask(dimensions.networkMaskId)})`}
              />
            </mask>
            <mask id={luminanceMask(dimensions.networkMaskId)}>
              <rect width="100%" height="100%" fill="white" />
              <rect
                x={networkRect.left - networkRect.stroke}
                y={networkRect.top - networkRect.stroke}
                height={networkRect.height + networkRect.stroke * 2}
                width={networkRect.width + networkRect.stroke * 2}
                rx={networkRect.radius + networkRect.stroke}
                fill="black"
              />
            </mask>

            <mask id={dimensions.activityMaskId}>
              <rect
                width="100%"
                height="100%"
                mask={`url(#${luminanceMask(dimensions.activityMaskId)})`}
              />
            </mask>
            <mask id={luminanceMask(dimensions.activityMaskId)}>
              <rect width="100%" height="100%" fill="white" />
              <rect
                x={activityRect.left - activityRect.stroke}
                y={activityRect.top - activityRect.stroke}
                height={activityRect.height + activityRect.stroke * 2}
                width={activityRect.width + activityRect.stroke * 2}
                rx={activityRect.radius + activityRect.stroke}
                fill="black"
              />
            </mask>
          </Fragment>
        );
      })}

      <mask id={SWIDGE_MASK_ID} maskContentUnits="objectBoundingBox">
        <rect
          width="100%"
          height="100%"
          mask={`url(#${luminanceMask(SWIDGE_MASK_ID)})`}
        />
      </mask>
      <mask id={luminanceMask(SWIDGE_MASK_ID)}>
        <rect width="100%" height="100%" fill="white" />
        <circle cx={1.2} cy={-0.2} r={1} fill="black" />
      </mask>
    </svg>
  );
}

// Fix for Safari not supporting `mask-mode: luminance`. We can use a luminance
// mask within the SVG but have to expose an alpha mask for use in CSS.
function luminanceMask(maskId: string) {
  return `${maskId}-luminance`;
}

/**
 * Provides SVG masks for the "activity" and "network" adornments of the
 * `Emblem` component.
 *
 * Place `EmblemMasks` towards the root of your component tree. It renders a
 * single SVG containing all masks within a portal.
 */
export const EmblemMasks = () => {
  return createPortal(<SvgMasks />, document.body);
};
