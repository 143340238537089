import { type ForwardedRef, forwardRef, SVGProps } from 'react';
import { type ProgressBarProps, ProgressBar } from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';

export type ProgressCircleProps = {
  /**
   * The prominence of the element.
   * @default 'default'
   */
  prominence?: 'low' | 'default' | 'high';
  /**
   * The size of the element.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large' | 'xlarge';
} & Omit<ProgressBarProps, 'children' | 'className' | 'style'> &
  StyleProps;

const SIZE_MAP = {
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '64px',
};
const STROKE_MAP = {
  small: '2px',
  medium: '2px',
  large: '3px',
  xlarge: '5px',
};

/**
 * Shows the progression of a system operation such as downloading, uploading,
 * processing, etc.
 */
export const ProgressCircle = forwardRef(function ProgressCircle(
  props: ProgressCircleProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    isIndeterminate,
    prominence = 'default',
    size = 'medium',
    style,
    ...otherProps
  } = props;

  return (
    <ProgressBar
      isIndeterminate={isIndeterminate}
      ref={ref}
      style={{
        // @ts-expect-error CSS Variable declaration is valid
        '--diameter': SIZE_MAP[size],
        '--stroke-width': STROKE_MAP[size],
        '--radius': 'calc(var(--diameter) / 2)',
        '--offset-radius': 'calc(var(--radius) - var(--stroke-width) / 2)',
        '--circumference': `calc(var(--offset-radius) * pi * 2)`,
        ...style,
      }}
      {...otherProps}
    >
      {(renderProps) => (
        <svg
          aria-hidden
          fill="none"
          focusable="false"
          role="img"
          className={cn('size-[var(--diameter)]', {
            // repurposed as stroke against the indicator circle
            'text-accent': prominence === 'high',
            'text-default': prominence === 'default',
            'text-subtle': prominence === 'low',
          })}
          style={{
            // @ts-expect-error CSS Variable declaration is valid
            '--fraction': renderProps.percentage
              ? renderProps.percentage / 100
              : 0,
          }}
        >
          {/* track */}
          <Circle className="stroke-interactive" />

          {/* indicator */}
          <Circle
            className={cn('stroke-current', {
              'animate-spin': isIndeterminate,
              'transition-all': !isIndeterminate,
            })}
            style={{
              strokeDasharray: 'var(--circumference)',
              strokeLinecap: 'round',
              transformOrigin: 'center',

              ...(isIndeterminate
                ? {
                    strokeDashoffset: `calc(var(--circumference) - 0.75 * var(--circumference))`,
                  }
                : {
                    strokeDashoffset: `calc(var(--circumference) - var(--fraction) * var(--circumference))`,
                    transform: 'rotate(-90deg)',
                  }),
            }}
          />
        </svg>
      )}
    </ProgressBar>
  );
});

function Circle(props: SVGProps<SVGCircleElement>) {
  const { style, ...otherProps } = props;
  return (
    <circle
      style={{
        // tailwind doesn't support these properties…
        // @ts-expect-error valid style properties on SVG circle
        cx: 'var(--radius)',
        cy: 'var(--radius)',
        r: 'var(--offset-radius)',
        strokeWidth: 'var(--stroke-width)',
        ...style,
      }}
      {...otherProps}
    />
  );
}
