import { type ForwardedRef, Fragment, forwardRef } from 'react';
import {
  type DialogProps as RACDialogProps,
  Button,
  composeRenderProps,
  Provider,
  Dialog as RACDialog,
} from 'react-aria-components';

import { CloseIcon } from '../../icons';
import { type StyleProps, cn } from '../../utils';
import { ContentContext, FooterContext, HeadingContext } from '../content';
import { type ModalProps, Modal } from './Modal';
import { useDialogState } from './useDialogState';

export type DialogProps = {
  /**
   * The size of the dialog.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large' | 'fullscreen';
} & Omit<RACDialogProps, 'className' | 'style' | 'role' | 'slot'> &
  Pick<ModalProps, 'isDismissable' | 'isKeyboardDismissDisabled'> &
  StyleProps;

/**
 * Dialogs are windows containing contextual information, tasks, or workflows
 * that appear over the user interface.
 */
export const Dialog = forwardRef(function Dialog(
  props: DialogProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const {
    className,
    isDismissable,
    isKeyboardDismissDisabled,
    size = 'medium',
    ...otherProps
  } = props;

  return (
    <Modal
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      size={size}
    >
      <RACDialog
        className={cn(
          // NOTE: "max-height" is inherited from the parent `Modal` element so
          // scrollable children behave as expected.
          'relative flex max-h-[inherit] max-w-full flex-col outline-none',
          { 'h-full': size === 'fullscreen' },
          className
        )}
        ref={forwardedRef}
        // @ts-expect-error Intentionally omitted from prop types; should
        // only be used by the `AlertDialog` component.
        role={props.role}
        {...otherProps}
      >
        {composeRenderProps(props.children, (children) => (
          <Provider
            values={[
              [
                HeadingContext,
                {
                  className:
                    'title-2xl-semibold border-b border-subtle py-4 pe-14 ps-6',
                  // TODO: I shouldn't have defaulted the slot value here. Remove and update product instances.
                  slot: 'title',
                },
              ],
              [ContentContext, { className: 'flex-1 overflow-y-auto p-6' }],
              [
                FooterContext,
                { className: 'border-t border-subtle px-6 py-4' },
              ],
            ]}
          >
            <Fragment>
              {children}
              {isDismissable && <DismissButton />}
            </Fragment>
          </Provider>
        ))}
      </RACDialog>
    </Modal>
  );
});

function DismissButton() {
  const state = useDialogState();
  return (
    <Button
      onPress={state.close}
      aria-label="dismiss"
      slot="close"
      className={cn(
        `text-secondary absolute end-6 top-4 flex h-8 w-8 items-center justify-center rounded-sm outline-none`,
        `hover:bg-surfaceTwo hover:text-default`,
        `focus-visible:bg-surfaceTwo focus-visible:text-default focus-visible:ring-1 focus-visible:ring-offset-2`,
        `pressed:bg-surfaceOne`
      )}
    >
      <CloseIcon className="h-5 w-5 text-current" />
    </Button>
  );
}
