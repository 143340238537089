export const NFT_FALLBACK_URL =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzgxODE4MSIgZD0iTTAgMGg2NHY2NEgweiIvPjxtYXNrIGlkPSJhIiB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHg9IjAiIHk9IjAiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiPjxwYXRoIGZpbGw9IiM4MTgxODEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTY0IDBIMHYxN2g2NFYwWm0wIDQ3SDB2MTdoNjRWNDdaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L21hc2s+PGcgbWFzaz0idXJsKCNhKSI+PHBhdGggc3Ryb2tlPSIjMDAwIiBzdHJva2Utb3BhY2l0eT0iLjUiIHN0cm9rZS13aWR0aD0iNCIgZD0iTTggMTkuNzIgMzIgNy4yNWwyNCAxMi40N3YyNC41NkwzMiA1Ni43NSA4IDQ0LjI4VjE5LjcyWiIvPjwvZz48cGF0aCBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii41IiBkPSJNMjQuMjggMjMuNTVWNDFIMjAuN2wtNi45NC0xMC4wN2gtLjExVjQxSDkuNDNWMjMuNTVoMy42M2w2Ljg3IDEwLjA1aC4xNFYyMy41NWg0LjIxWk0yNi45MyA0MVYyMy41NWgxMS45MnYzLjQyaC03Ljd2My41OGg2Ljk0VjM0aC02Ljk0VjQxaC00LjIyWk00MC40IDI2Ljk3di0zLjQyaDE0Ljc2djMuNDJoLTUuM1Y0MUg0NS43VjI2Ljk3aC01LjNaIi8+PC9zdmc+';
export const TOKEN_FALLBACK_URL =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzgxODE4MSIgZD0iTTAgMGg2NHY2NEgweiIvPjxtYXNrIGlkPSJhIiB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHg9IjAiIHk9IjAiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiPjxwYXRoIGZpbGw9IiM4MTgxODEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTY0IDBIMHYxOWg2NFYwWm0wIDQ1SDB2MTloNjRWNDVaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L21hc2s+PGcgbWFzaz0idXJsKCNhKSI+PGNpcmNsZSBjeD0iMzIiIGN5PSIzMiIgcj0iMjQiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLW9wYWNpdHk9Ii41IiBzdHJva2Utd2lkdGg9IjQiLz48L2c+PHBhdGggZmlsbD0iIzAwMCIgZmlsbC1vcGFjaXR5PSIuNSIgZD0iTTMxLjQzIDQ3LjFWMThoMS44N3YyOS4xaC0xLjg3Wm00Ljk1LTE5LjVhMi44OCAyLjg4IDAgMCAwLTEuMTgtMi4xMyA0LjY0IDQuNjQgMCAwIDAtMi44LS43N2MtLjggMC0xLjQ4LjEyLTIuMDQuMzUtLjU1LjIyLS45Ny41Mi0xLjI3LjkyLS4yOS4zOS0uNDMuODQtLjQzIDEuMzQtLjAyLjQxLjA3Ljc4LjI2IDEuMDkuMi4zLjQ3LjU4LjguOC4zNS4yMi43NC40MiAxLjE5LjU4LjQ1LjE2LjkyLjMgMS40My40MWwyLjEuNWMxIC4yMyAxLjk0LjUzIDIuNzkuOTFhOC41IDguNSAwIDAgMSAyLjIgMS40IDUuOCA1LjggMCAwIDEgMS45OCA0LjUzYzAgMS40My0uMzcgMi42Ni0xLjEgMy43YTYuOTIgNi45MiAwIDAgMS0zLjA4IDIuNDNjLTEuMzQuNTctMi45Ni44NS00Ljg1Ljg1YTEyLjggMTIuOCAwIDAgMS00LjkxLS44NiA3LjE5IDcuMTkgMCAwIDEtMy4yNS0yLjU2QTcuNjQgNy42NCAwIDAgMSAyMyAzNi44N2g0Ljc2Yy4wNS43OS4yOCAxLjQ0LjY3IDEuOTYuNC41MS45NC45IDEuNiAxLjE3LjY4LjI2IDEuNDQuMzkgMi4yOS4zOWE1LjkgNS45IDAgMCAwIDIuMTctLjM3Yy42Mi0uMjQgMS4xLS41OCAxLjQ0LTEgLjM0LS40NC41MS0uOTQuNTEtMS41IDAtLjUyLS4xNS0uOTYtLjQ2LTEuMzItLjMtLjM1LS43NS0uNjUtMS4zNC0uOS0uNTktLjI1LTEuMy0uNDgtMi4xNS0uNjlsLTIuNTQtLjYzYTEwLjYzIDEwLjYzIDAgMCAxLTQuNjQtMi4yNCA1LjIgNS4yIDAgMCAxLTEuNy00LjFjMC0xLjQxLjM3LTIuNjQgMS4xMy0zLjdhNy41OSA3LjU5IDAgMCAxIDMuMTUtMi40NmMxLjMzLS42IDIuODQtLjg5IDQuNTQtLjg5IDEuNzMgMCAzLjI0LjMgNC41Mi44OSAxLjMuNTkgMi4zIDEuNDEgMy4wMyAyLjQ2YTYuNTIgNi41MiAwIDAgMSAxLjExIDMuNjZoLTQuNzJaIi8+PC9zdmc+';

export const EMBLEM_DIMENSIONS = {
  xsmall: {
    size: 16,
    nftRadius: 2,
    activityRect: {
      top: -2,
      left: -2,
      height: 8,
      width: 8,
      stroke: 1,
      radius: 2,
    },
    activityMaskId: 'emblem-activity-mask-xsmall',
    networkRect: {
      top: 8,
      left: 8,
      height: 8,
      width: 8,
      stroke: 1,
      radius: 2,
    },
    networkMaskId: 'emblem-network-mask-xsmall',
  },
  small: {
    size: 24,
    nftRadius: 4,
    activityRect: {
      top: -2,
      left: -2,
      height: 12,
      width: 12,
      stroke: 2,
      radius: 3,
    },
    activityMaskId: 'emblem-activity-mask-small',
    networkRect: {
      top: 12,
      left: 12,
      height: 12,
      width: 12,
      stroke: 2,
      radius: 3,
    },
    networkMaskId: 'emblem-network-mask-small',
  },
  medium: {
    size: 32,
    nftRadius: 6,
    activityRect: {
      top: -3,
      left: -3,
      height: 16,
      width: 16,
      stroke: 2,
      radius: 4,
    },
    activityMaskId: 'emblem-activity-mask-medium',
    networkRect: {
      top: 16,
      left: 16,
      height: 16,
      width: 16,
      stroke: 2,
      radius: 4,
    },
    networkMaskId: 'emblem-network-mask-medium',
  },
  large: {
    size: 40,
    nftRadius: 8,
    activityRect: {
      top: -4,
      left: -4,
      height: 20,
      width: 20,
      stroke: 2,
      radius: 6,
    },
    activityMaskId: 'emblem-activity-mask-large',
    networkRect: {
      top: 20,
      left: 20,
      height: 20,
      width: 20,
      stroke: 2,
      radius: 6,
    },
    networkMaskId: 'emblem-network-mask-large',
  },
  xlarge: {
    size: 56,
    nftRadius: 8,
    activityRect: {
      top: -6,
      left: -6,
      height: 28,
      width: 28,
      stroke: 3,
      radius: 8,
    },
    activityMaskId: 'emblem-activity-mask-xlarge',
    networkRect: {
      top: 28,
      left: 28,
      height: 28,
      width: 28,
      stroke: 3,
      radius: 8,
    },
    networkMaskId: 'emblem-network-mask-xlarge',
  },
};

export const ACTIVITY_METADATA = {
  airdrop: {
    name: 'Airdrop',
    glyph: (
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M11.81 3.24a7 7 0 0 0-8.57 4.95c-.11.42.12.8.5.97.05.02.1.06.14.11l3.11 4.23-.47 1.78a1 1 0 0 0 .7 1.22l1.93.52a1 1 0 0 0 1.23-.7l.48-1.78 4.8-2.11a.36.36 0 0 1 .19-.03c.4.05.8-.17.91-.59a7 7 0 0 0-4.95-8.57ZM9.2 10.1a.3.3 0 0 0-.13-.33c-1-.6-2.01-.92-3.09-.9-.23 0-.34.26-.2.45l2.36 3.22c.05.06.11.1.18.11l.18.05.7-2.6Zm4.9 1.38c.21-.1.24-.37.05-.49a6.02 6.02 0 0 0-3.23-.7.3.3 0 0 0-.28.22l-.7 2.58.3.08 3.86-1.7Z"
        />
      </svg>
    ),
  },
  move: {
    name: 'Move',
    glyph: (
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path d="M7.27 7.49c0-1.6 1.3-2.9 2.9-2.9h5c1.6 0 2.9 1.3 2.9 2.9v5.02c0 1.6-1.3 2.9-2.9 2.9h-5a2.9 2.9 0 0 1-2.9-2.9V7.5Zm2.9-1.1c-.6 0-1.1.49-1.1 1.1v5.02c0 .6.5 1.1 1.1 1.1h5c.6 0 1.1-.5 1.1-1.1V7.5c0-.61-.5-1.1-1.1-1.1h-5ZM1.93 7.84c0-1.6 1.3-2.9 2.9-2.9h.35a.9.9 0 1 1 0 1.8h-.35c-.6 0-1.1.49-1.1 1.1v.33a.9.9 0 0 1-1.8 0v-.33Zm.9 3.1c.5 0 .9.4.9.9v.32c0 .61.5 1.1 1.1 1.1h.35a.9.9 0 1 1 0 1.8h-.35a2.9 2.9 0 0 1-2.9-2.9v-.33c0-.5.4-.9.9-.9Z" />
      </svg>
    ),
  },
  receive: {
    name: 'Receive',
    glyph: (
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path d="M9.12 12.27V5.23c0-.25.08-.45.25-.63a.85.85 0 0 1 .63-.25c.25 0 .45.08.63.25.17.18.25.38.25.63v7.04l2.9-2.9a.87.87 0 0 1 1.25 0 .9.9 0 0 1 .26.63c0 .24-.09.45-.26.63l-4.4 4.4a.86.86 0 0 1-.63.26.9.9 0 0 1-.63-.26l-4.4-4.41A.84.84 0 0 1 4.7 10c0-.24.1-.45.26-.63a.86.86 0 0 1 .63-.26c.25 0 .46.09.63.26l2.9 2.9Z" />
      </svg>
    ),
  },
  redemption: {
    name: 'Redemption',
    glyph: (
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path d="M5.36 5.36a.9.9 0 0 1 1.28 0l4 4a.9.9 0 0 1 0 1.28l-4 4a.9.9 0 0 1-1.28-1.28L8.73 10 5.36 6.64a.9.9 0 0 1 0-1.28Zm5 0a.9.9 0 0 1 1.28 0l4 4a.9.9 0 0 1 0 1.28l-4 4a.9.9 0 0 1-1.28-1.28L13.73 10l-3.37-3.36a.9.9 0 0 1 0-1.28Z" />
      </svg>
    ),
  },
  send: {
    name: 'Send',
    glyph: (
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path d="m9.12 7.73-2.9 2.9a.87.87 0 0 1-1.25 0A.9.9 0 0 1 4.7 10c0-.24.09-.45.26-.63l4.4-4.4a.86.86 0 0 1 .63-.26.9.9 0 0 1 .63.26l4.4 4.41a.87.87 0 0 1 0 1.24.86.86 0 0 1-.62.27.86.86 0 0 1-.63-.26l-2.9-2.9v7.04c0 .25-.08.45-.25.63a.85.85 0 0 1-.63.25.85.85 0 0 1-.63-.25.85.85 0 0 1-.25-.63V7.73Z" />
      </svg>
    ),
  },
  swidge: {
    name: 'Swidge',
    glyph: (
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path d="M7 13.73c-.5.43-1.04.63-1.6.59a2.55 2.55 0 0 1-2.35-1.94 2.13 2.13 0 0 1 .3-1.73l1.2-1.97a3.02 3.02 0 0 1-.87-2.08c0-.8.28-1.5.85-2.07a2.82 2.82 0 0 1 2.07-.85c.8 0 1.5.28 2.07.85.57.57.85 1.26.85 2.07 0 .8-.28 1.5-.85 2.07a2.82 2.82 0 0 1-2.56.8l-1.24 2.1a.6.6 0 0 0-.09.46.68.68 0 0 0 .63.54.6.6 0 0 0 .45-.16L13 6.27a2.22 2.22 0 0 1 1.64-.6 2.55 2.55 0 0 1 2.32 1.95c.14.57.03 1.14-.32 1.73l-1.18 1.98a2.97 2.97 0 0 1 .86 2.07c0 .8-.29 1.5-.85 2.07-.57.57-1.26.85-2.07.85-.81 0-1.5-.28-2.07-.85a2.82 2.82 0 0 1-.85-2.07c0-.8.28-1.5.85-2.07a2.82 2.82 0 0 1 2.55-.8l1.25-2.09c.1-.15.13-.3.09-.46a.73.73 0 0 0-.24-.38.67.67 0 0 0-.4-.16.6.6 0 0 0-.44.16L7 13.73Z" />
      </svg>
    ),
  },
};

const networkSvgAttributes = {
  'aria-hidden': true,
  fill: 'none',
  focusable: 'false',
  role: 'img',
  tabIndex: -1,
  viewBox: '0 0 24 24',
  xmlns: 'http://www.w3.org/2000/svg',
} as const;

export const NETWORK_METADATA = {
  arbitrum: {
    name: 'Arbitrum',
    glyph: (
      <svg {...networkSvgAttributes}>
        <rect width="24" height="24" fill="#213147" />
        <path
          d="M14.5655 9.56935C14.6124 9.44812 14.7806 9.44812 14.8236 9.56935L17.6081 17.2034L16.0164 18.1224L13.7637 11.9472C13.7403 11.8885 13.7403 11.822 13.7637 11.7594L14.5655 9.56935Z"
          fill="#12AAFF"
        />
        <path
          d="M12.4219 15.4474L13.2236 13.2573C13.2667 13.1361 13.4348 13.1361 13.4778 13.2534L15.3864 18.4862L13.7946 19.4052L12.4219 15.6351C12.3984 15.5765 12.3984 15.5061 12.4219 15.4474Z"
          fill="#12AAFF"
        />
        <path
          d="M12.1134 4.80203C12.0782 4.78247 12.0391 4.77074 12 4.77074C11.9609 4.77074 11.9218 4.78247 11.8866 4.80203L5.82472 8.30226C5.75432 8.34137 5.7113 8.41568 5.7113 8.49781V15.5022C5.7113 15.5843 5.75432 15.6586 5.82472 15.6977L8.34855 17.155L7.99158 18.1286L5.31239 16.5816C4.92522 16.3587 4.68665 15.9441 4.68665 15.4983V8.49781C4.68665 8.05197 4.92913 7.64132 5.31239 7.4184L11.3743 3.91817C11.5659 3.80866 11.7849 3.75 12 3.75C12.2151 3.75 12.4302 3.80866 12.6257 3.91817L18.6876 7.41449C19.0748 7.63741 19.3133 8.05197 19.3133 8.49781V15.4983C19.3133 15.948 19.0748 16.3587 18.6876 16.5816L12.6257 20.0818C12.4341 20.1913 12.219 20.25 12 20.25C11.781 20.25 11.5659 20.1952 11.3743 20.0818L8.6245 18.4936L9.41325 17.7698L11.8866 19.198C11.9218 19.2175 11.9609 19.2293 12 19.2293C12.0391 19.2293 12.0782 19.2175 12.1134 19.198L18.1753 15.6938C18.2457 15.6547 18.2887 15.5804 18.2887 15.4983V8.49781C18.2887 8.41568 18.2457 8.34137 18.1753 8.30226L12.1134 4.80203Z"
          fill="#9DCCED"
        />
        <path
          d="M9.95098 8.00114H11.488C11.5857 8.00114 11.6522 8.095 11.6209 8.18495L7.99163 18.1342L6.3999 17.2152L9.69286 8.18104C9.73197 8.07153 9.83756 8.00114 9.95098 8.00114Z"
          fill="#FFFFFF"
        />
        <path
          d="M12.6412 8.00114H14.1782C14.272 8.00114 14.3424 8.095 14.3072 8.18495L10.2165 19.4131L8.6245 18.4936L12.3831 8.18104C12.4222 8.07153 12.5278 8.00114 12.6412 8.00114Z"
          fill="#FFFFFF"
        />
      </svg>
    ),
  },
  base: {
    name: 'Base',
    glyph: (
      <svg {...networkSvgAttributes}>
        <rect width="24" height="24" fill="#0052FF" />
        <path
          d="M11.9856 20.25C16.55 20.25 20.2499 16.5563 20.2499 12C20.2499 7.44365 16.55 3.75001 11.9856 3.75001C7.65532 3.75001 4.10288 7.07469 3.75006 11.3065H14.6737V12.6935H3.75006C4.10288 16.9253 7.65532 20.25 11.9856 20.25Z"
          fill="#FFFFFF"
        />
      </svg>
    ),
  },
  blast: {
    name: 'Blast',
    glyph: (
      <svg {...networkSvgAttributes}>
        <rect width="24" height="24" fill="#000" />
        <path
          fill="#FCFC03"
          d="m18.74 10.72-2.38 1.19-.02.03 1.62 1.2-.86 2.66-2.42 1.2H5.43L7.9 9.2l1.66 1.24-1.5 4.65h7.23l.83-2.54h-5.16l.5-1.55h5.16l.68-2.12H4.43L6.97 7h10.95l1.65 1.2-.83 2.52Z"
        />
      </svg>
    ),
  },
  ethereum: {
    name: 'Ethereum',
    glyph: (
      <svg {...networkSvgAttributes}>
        <rect width="24" height="24" fill="#FFFFFF" />
        <path d="M11.9986 2L6 11.9524L11.9986 9.22649V2Z" fill="#8A92B2" />
        <path
          d="M11.9986 9.22649L6 11.9524L11.9986 15.4984V9.22649Z"
          fill="#62688F"
        />
        <path
          d="M17.9967 11.9524L11.9986 2V9.22649L17.9967 11.9524Z"
          fill="#62688F"
        />
        <path
          d="M11.9986 15.4984L17.9967 11.9524L11.9986 9.22649V15.4984Z"
          fill="#454A75"
        />
        <path
          d="M6 13.0903L11.9986 21.5425V16.6341L6 13.0903Z"
          fill="#8A92B2"
        />
        <path
          d="M11.9986 16.6341V21.5425L18 13.0903L11.9986 16.6341Z"
          fill="#62688F"
        />
      </svg>
    ),
  },
  optimism: {
    name: 'Optimism',
    glyph: (
      <svg {...networkSvgAttributes}>
        <rect width="24" height="24" fill="#FF0420" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.74618 14.685C7.20252 15.0213 7.78793 15.1894 8.50245 15.1894C9.36713 15.1894 10.0576 14.9882 10.574 14.5859C11.0904 14.1777 11.4536 13.5622 11.6638 12.7396C11.7899 12.2352 11.898 11.7158 11.988 11.1815C12.0181 10.9893 12.0331 10.8302 12.0331 10.7041C12.0331 10.2838 11.928 9.92351 11.7178 9.62332C11.5077 9.31708 11.2195 9.08889 10.8532 8.9388C10.4869 8.7827 10.0726 8.70465 9.61029 8.70465C7.91105 8.70465 6.85725 9.53025 6.44897 11.1815C6.30487 11.7879 6.1938 12.3073 6.1157 12.7396C6.0857 12.9317 6.07068 13.0939 6.07068 13.226C6.07068 13.8564 6.29585 14.3428 6.74618 14.685ZM9.4842 13.5952C9.25001 13.7934 8.96484 13.8924 8.62855 13.8924C8.05217 13.8924 7.76393 13.6163 7.76393 13.0638C7.76393 12.9317 7.77593 12.8056 7.79997 12.6856C7.90802 12.1031 8.01612 11.6108 8.12422 11.2085C8.22626 10.8002 8.39441 10.497 8.62855 10.2988C8.86874 10.1006 9.15693 10.0016 9.49322 10.0016C10.0636 10.0016 10.3488 10.2748 10.3488 10.8212C10.3488 10.9533 10.3368 11.0824 10.3128 11.2085C10.2408 11.6288 10.1357 12.1211 9.99756 12.6856C9.89551 13.0939 9.72439 13.3971 9.4842 13.5952Z"
          fill="#FFFFFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2933 15.0363C12.3293 15.0783 12.3804 15.0993 12.4464 15.0993H13.6713C13.7313 15.0993 13.7884 15.0783 13.8424 15.0363C13.8964 14.9942 13.9295 14.9402 13.9415 14.8742L14.3558 12.9017H15.5717C16.3583 12.9017 16.9767 12.7366 17.4271 12.4064C17.8834 12.0761 18.1866 11.5657 18.3367 10.8752C18.3728 10.7131 18.3908 10.557 18.3908 10.4069C18.3908 9.88448 18.1866 9.48522 17.7783 9.20898C17.376 8.93279 16.8416 8.79469 16.1751 8.79469H13.7794C13.7193 8.79469 13.6623 8.81572 13.6082 8.85772C13.5542 8.89976 13.5212 8.95381 13.5092 9.01986L12.2663 14.8742C12.2543 14.9342 12.2632 14.9882 12.2933 15.0363ZM16.2922 11.4516C16.1061 11.5897 15.8869 11.6588 15.6348 11.6588H14.599L14.9412 10.0466H16.022C16.2682 10.0466 16.4423 10.0946 16.5444 10.1907C16.6465 10.2808 16.6975 10.4129 16.6975 10.587C16.6975 10.6651 16.6885 10.7551 16.6705 10.8572C16.6104 11.1154 16.4844 11.3135 16.2922 11.4516Z"
          fill="#FFFFFF"
        />
      </svg>
    ),
  },
  polygon: {
    name: 'Polygon',
    glyph: (
      <svg {...networkSvgAttributes}>
        <rect width="24" height="24" fill="url(#polygon_network_gradient)" />
        <path
          d="M15.4692 14.5886L18.863 12.6289C19.0428 12.5251 19.154 12.3321 19.154 12.1244V8.20555C19.154 7.99837 19.0423 7.8049 18.863 7.70106L15.4692 5.74139C15.2895 5.63755 15.0666 5.63804 14.8869 5.74139L11.4931 7.70106C11.3133 7.8049 11.2021 7.99837 11.2021 8.20555V15.2091L8.8222 16.583L6.44228 15.2091V12.4609L8.8222 11.087L10.392 11.9931V10.1496L9.11314 9.41143C9.02498 9.36049 8.92408 9.33355 8.82171 9.33355C8.71935 9.33355 8.61845 9.36049 8.53077 9.41143L5.13698 11.3711C4.95722 11.4749 4.84604 11.6679 4.84604 11.8756V15.7945C4.84604 16.0016 4.95771 16.1951 5.13698 16.2989L8.53077 18.2586C8.71004 18.362 8.93339 18.362 9.11314 18.2586L12.5069 16.2994C12.6867 16.1956 12.7979 16.0021 12.7979 15.7949V8.79135L12.841 8.76686L15.1778 7.41747L17.5577 8.79135V11.5396L15.1778 12.9135L13.6104 12.0083V13.8519L14.8869 14.5891C15.0666 14.6924 15.2895 14.6919 15.4692 14.5886Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="polygon_network_gradient"
            x1="-4.38598"
            y1="1.97255"
            x2="20.3902"
            y2="17.1344"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A229C5" />
            <stop offset="1" stopColor="#7B3FE4" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  solana: {
    name: 'Solana',
    glyph: (
      <svg {...networkSvgAttributes}>
        <rect width="24" height="24" fill="#000000" />
        <path
          d="M18.7018 15.4611L16.4282 17.8366C16.3788 17.8882 16.319 17.9293 16.2525 17.9574C16.1861 17.9855 16.1144 18 16.042 18H5.26395C5.21252 18 5.16221 17.9854 5.11921 17.9579C5.0762 17.9304 5.04237 17.8913 5.02187 17.8454C5.00138 17.7994 4.9951 17.7486 5.00382 17.6993C5.01254 17.6499 5.03588 17.6041 5.07097 17.5674L7.34629 15.192C7.39558 15.1405 7.45519 15.0995 7.52142 15.0714C7.58766 15.0433 7.65912 15.0287 7.73138 15.0286H18.5088C18.5602 15.0286 18.6105 15.0432 18.6535 15.0707C18.6965 15.0982 18.7303 15.1373 18.7509 15.1832C18.7714 15.2292 18.7776 15.2799 18.7689 15.3293C18.7602 15.3787 18.7369 15.4245 18.7018 15.4611ZM16.4282 10.6777C16.3788 10.6261 16.319 10.585 16.2525 10.5569C16.1861 10.5287 16.1144 10.5143 16.042 10.5143H5.26395C5.21252 10.5143 5.16221 10.5289 5.11921 10.5564C5.0762 10.5839 5.04237 10.623 5.02187 10.6689C5.00138 10.7149 4.9951 10.7657 5.00382 10.815C5.01254 10.8644 5.03588 10.9102 5.07097 10.9469L7.34629 13.3223C7.39558 13.3738 7.45519 13.4148 7.52142 13.4429C7.58766 13.471 7.65912 13.4856 7.73138 13.4857H18.5088C18.5602 13.4857 18.6105 13.4711 18.6535 13.4436C18.6965 13.4161 18.7303 13.377 18.7509 13.3311C18.7714 13.2851 18.7776 13.2343 18.7689 13.185C18.7602 13.1356 18.7369 13.0898 18.7018 13.0531L16.4282 10.6777ZM5.26395 8.97143H16.042C16.1144 8.97146 16.1861 8.95698 16.2525 8.92886C16.319 8.90074 16.3788 8.8596 16.4282 8.808L18.7018 6.43257C18.7369 6.39594 18.7602 6.35012 18.7689 6.30075C18.7776 6.25138 18.7714 6.2006 18.7509 6.15465C18.7303 6.1087 18.6965 6.06959 18.6535 6.04211C18.6105 6.01464 18.5602 6 18.5088 6H7.73138C7.65912 6.00012 7.58766 6.01469 7.52142 6.0428C7.45519 6.07091 7.39558 6.11197 7.34629 6.16343L5.07155 8.53886C5.0365 8.57545 5.01318 8.62122 5.00443 8.67053C4.99569 8.71985 5.00192 8.7706 5.02234 8.81652C5.04277 8.86245 5.07651 8.90156 5.11943 8.92908C5.16235 8.9566 5.21257 8.97131 5.26395 8.97143Z"
          fill="url(#solana_network_gradient)"
        />
        <defs>
          <linearGradient
            id="solana_network_gradient"
            x1="6.16258"
            y1="18.286"
            x2="17.1354"
            y2="5.5887"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.08" stopColor="#9945FF" />
            <stop offset="0.3" stopColor="#8752F3" />
            <stop offset="0.5" stopColor="#5497D5" />
            <stop offset="0.6" stopColor="#43B4CA" />
            <stop offset="0.72" stopColor="#28E0B9" />
            <stop offset="0.97" stopColor="#19FB9B" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
};
