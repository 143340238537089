import { Client } from '@infinex/client';
import { Address } from 'viem';
import { create } from 'zustand';

type UserState = {
  session: Session | undefined;
  vestingSchedule: VestingSchedule | undefined;
};

type UserActions = {
  setSession: (session: Session | undefined) => void;
  fetchSession: (client: Client) => Promise<Session>;
  logout: (client: Client) => Promise<boolean>;

  fetchVestingSchedule: (
    client: Client,
    address: Address | undefined
  ) => Promise<VestingSchedule>;
};

export const useUserStore = create<UserState & UserActions>((set, get) => ({
  session: undefined,
  vestingSchedule: undefined,
  setSession: (session) => set({ session }),

  fetchSession: async (client) => {
    return client.patronClaim.getSession().then((session) => {
      set({
        session,
      });

      return session;
    });
  },

  logout: async (client) => {
    return client.patronClaim.signout().then((session) => {
      set({
        session: undefined,
      });

      return session;
    });
  },

  fetchVestingSchedule: async (client, address) => {
    return client.patronClaim
      .getVestingSchedule({ address: address! })
      .then((vestingSchedule) => {
        set({
          vestingSchedule,
        });

        return vestingSchedule;
      });
  },
}));

type GetSessionResponse = Client['patronClaim']['getSession'];
export type Session = Awaited<ReturnType<GetSessionResponse>>;
export type UserStore = UserState & UserActions;

type VestingScheduleResponse = Client['patronClaim']['getVestingSchedule'];
export type VestingSchedule = Awaited<ReturnType<VestingScheduleResponse>>;
