import { filterDOMProps, useObjectRef, useSlotId } from '@react-aria/utils';
import { DOMProps } from '@react-types/shared';
import { forwardRef, ReactNode, useMemo } from 'react';
import { Provider } from 'react-aria-components';

import { IconContext } from '../../icons';
import { type StyleProps, cn, useHasChild } from '../../utils';

import { ButtonContext, LinkButtonContext } from '../button';
import { ContentContext, FooterContext, HeadingContext } from '../content';

export type EmptyStateProps = {
  /**
   * The content of the empty state.
   */
  children: ReactNode;
  /**
   * The size of the empty state. Influences typographic elements, and any
   * buttons provided.
   *
   * @default 'medium'
   */
  size?: 'small' | 'medium';
} & DOMProps &
  StyleProps;

/**
 * Empty states are used as placeholders when content is not yet available, or
 * is temporarily absent due to the state of the view.
 */
export const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(
  function EmptyState(props, forwardedRef) {
    const {
      children,
      className,
      size = 'medium',
      style,
      ...otherProps
    } = props;

    const regionRef = useObjectRef(forwardedRef);
    const hasHeadingSlot = useHasChild('[data-empty-state=heading]', regionRef);
    const headingId = useSlotId([hasHeadingSlot]);

    const slotClasses = useMemo(() => getSlotClasses(size), [size]);

    return (
      <div
        {...filterDOMProps(otherProps)}
        aria-labelledby={headingId}
        role="region"
        ref={regionRef}
        className={cn(
          'text-secondary flex min-h-20 flex-col items-center justify-center text-center',
          {
            'body-sm-normal gap-4 p-4': size === 'small',
            'body-base-normal gap-6 p-6': size === 'medium',
          },
          className
        )}
        style={style}
      >
        <Provider
          values={[
            [
              HeadingContext,
              {
                id: headingId,
                className: slotClasses.heading,
                // @ts-expect-error — data attributes valid for any element
                'data-empty-state': 'heading',
              },
            ],
            [IconContext, { className: slotClasses.icon }],
            [ContentContext, { className: slotClasses.content }],
            [FooterContext, { className: slotClasses.footer }],
            [ButtonContext, { size }],
            [LinkButtonContext, { size }],
          ]}
        >
          {children}
        </Provider>
      </div>
    );
  }
);

function getSlotClasses(size: EmptyStateProps['size']) {
  return {
    icon: cn('fill-default', {
      'size-8': size === 'medium',
      'size-6': size === 'small',
    }),
    heading: cn('text-default', {
      'body-xl-semibold': size === 'medium',
      'body-base-semibold': size === 'small',
    }),
    content: cn('flex flex-col items-center gap-2 text-pretty', {
      'max-w-md': size === 'small',
      'max-w-lg': size === 'medium',
    }),
    footer: cn(
      'flex flex-col self-stretch xs:items-center xs:justify-center xs:flex-row',
      {
        'gap-2': size === 'small',
        'gap-3': size === 'medium',
      }
    ),
  };
}
