import {
  type ForwardedRef,
  type HTMLAttributes,
  type ImgHTMLAttributes,
  createContext,
  forwardRef,
  useContext,
} from 'react';
import {
  type HeadingProps as RACHeadingProps,
  type SlotProps,
  type TextProps as RACTextProps,
  Header as RACHeader,
  Heading as RACHeading,
  Text as RACText,
  TextContext as RACTextContext,
  useContextProps,
  useSlottedContext,
} from 'react-aria-components';

/** Valid elements for components that allow `elementType` to be set. */
export type SlotElement =
  | 'aside'
  | 'div'
  | 'footer'
  | 'header'
  | 'section'
  | 'nav';

type SlottedValue<T> = {
  slots?: Record<string | symbol, T>;
};
export type SlottedContextValue<T> = SlottedValue<T> | T | null | undefined;
export type ContextValue<T, E extends Element> = SlottedContextValue<
  WithRef<T, E>
>;
export type WithRef<T, E> = T & { ref?: ForwardedRef<E> };

// Heading
// -----------------------------------------------------------------------------

type HeadingProps = {
  /**
   * Whether the element should be hidden, via context as a slotted component.
   */
  isHidden?: boolean;
} & RACHeadingProps;

export const HeadingContext = createContext<
  ContextValue<HeadingProps, HTMLHeadingElement>
>({});

/**
 * Represents a heading within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Heading = forwardRef(function Heading(
  props: HeadingProps,
  ref: ForwardedRef<HTMLHeadingElement>
) {
  [props, ref] = useContextProps(props, ref, HeadingContext);

  if (props.isHidden) {
    return null;
  }

  return <RACHeading {...props} ref={ref} />;
});

// Header
// -----------------------------------------------------------------------------

type HeaderProps = {
  /**
   * Whether the element should be hidden, via context as a slotted component.
   */
  isHidden?: boolean;
} & HTMLAttributes<HTMLElement>;

export const HeaderContext = createContext<
  ContextValue<HeaderProps, HTMLElement>
>({});

/**
 * Represents a header within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Header = forwardRef(function Header(
  props: HeaderProps,
  ref: ForwardedRef<HTMLElement>
) {
  [props, ref] = useContextProps(props, ref, HeaderContext);

  if (props.isHidden) {
    return null;
  }

  return <RACHeader {...props} ref={ref} />;
});

// Content
// -----------------------------------------------------------------------------

type ContentProps = {
  /**
   * The underlying HTML element to render.
   * @default 'div'
   */
  elementType?: SlotElement;
  /**
   * Whether the element should be hidden, via context as a slotted component.
   */
  isHidden?: boolean;
} & HTMLAttributes<HTMLElement>;

export const ContentContext = createContext<
  ContextValue<ContentProps, HTMLDivElement>
>({});

/**
 * Represents the primary content area within a component-lib container.
 * Provides no styling by itself, but receives cosmetic and layout styles from
 * the parent container.
 */
export const Content = forwardRef(function Content(
  props: ContentProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  [props, ref] = useContextProps(props, ref, ContentContext);
  const { elementType: Element = 'div', ...otherProps } = props;

  if (props.isHidden) {
    return null;
  }

  return <Element {...otherProps} ref={ref} />;
});

// Text
// -----------------------------------------------------------------------------

type TextProps = {
  /**
   * Whether the element should be hidden, via context as a slotted component.
   */
  isHidden?: boolean;
} & RACTextProps;

export const TextContext = createContext<
  ContextValue<TextProps, HTMLDivElement>
>({});

/**
 * Represents text with no specific semantic meaning within a component-lib
 * container. Provides no styling by itself, but receives cosmetic and layout
 * styles from the parent container.
 */
export const Text = forwardRef(function Text(
  props: TextProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  [props, ref] = useContextProps(props, ref, TextContext);
  const racContext = useContext(RACTextContext);

  if (props.isHidden) {
    return null;
  }

  const text = <RACText ref={ref} {...props} />;

  // Avoid errors being thrown when a slot is provided that's not defined by
  // some RAC provider for TextContext.
  if (
    props.slot &&
    racContext &&
    'slots' in racContext &&
    !racContext.slots?.[props.slot]
  ) {
    return (
      <RACTextContext.Provider value={null}>{text}</RACTextContext.Provider>
    );
  }

  return text;
});

// Footer
// -----------------------------------------------------------------------------

type FooterProps = {
  /**
   * The underlying HTML element to render.
   * @default 'footer'
   */
  elementType?: SlotElement;
  /**
   * Whether the element should be hidden, via context as a slotted component.
   */
  isHidden?: boolean;
} & HTMLAttributes<HTMLElement>;

export const FooterContext = createContext<
  ContextValue<FooterProps, HTMLDivElement>
>({});

/**
 * Represents a footer within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Footer = forwardRef(function Footer(
  props: FooterProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  [props, ref] = useContextProps(props, ref, FooterContext);
  const { elementType: Element = 'footer', ...otherProps } = props;

  if (props.isHidden) {
    return null;
  }

  return <Element {...otherProps} ref={ref} />;
});

// Image
// -----------------------------------------------------------------------------

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & SlotProps;
type ImageContextValue = {
  /**
   * Whether the element should be hidden, within a slotted component.
   */
  isHidden?: boolean;
} & ImageProps;

export const ImageContext = createContext<
  ContextValue<ImageContextValue, HTMLImageElement>
>({});

/**
 * Represents an image within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Image = forwardRef(function Image(
  props: ImageProps,
  ref: ForwardedRef<HTMLImageElement>
) {
  [props, ref] = useContextProps(props, ref, ImageContext);
  const ctx = useSlottedContext(ImageContext);

  if (ctx?.isHidden) {
    return null;
  }

  if (props.alt == null) {
    console.warn(
      'The `alt` prop was not provided to an image. ' +
        'Add `alt` text for screen readers, or set `alt=""` prop to indicate that the image ' +
        'is decorative or redundant with displayed text and should not be announced by screen readers.'
    );
  }

  return <img {...props} ref={ref} slot={props.slot || 'image'} />;
});
