import { createIcon } from '../createIcon';

export const SparkleIcon = createIcon(
  <>
    <path d="M16.9653 8.28998C17.1383 8.30592 17.1388 8.5784 16.9657 8.59492C10.724 9.19076 9.80947 10.1038 9.20663 16.0303C9.18921 16.2015 8.92257 16.202 8.90443 16.0309C8.27637 10.1039 7.33983 9.19249 1.11584 8.61714C0.942806 8.60114 0.942371 8.32878 1.11535 8.31221C7.33574 7.71635 8.2503 6.80332 8.87498 0.876293C8.89302 0.70514 9.15967 0.705608 9.17718 0.876815C9.7834 6.80321 10.72 7.71464 16.9653 8.28998Z" />
    <path d="M17.0959 21.0004C17.2199 21.0162 17.2201 21.2325 17.0961 21.2487C15.4455 21.4651 15.011 21.8779 14.7967 23.4363C14.7797 23.5597 14.5634 23.5605 14.5457 23.4373C14.3247 21.9018 13.9096 21.4807 12.3093 21.275C12.1847 21.2589 12.1839 21.0345 12.3084 21.0176C13.8927 20.8027 14.3156 20.3888 14.5262 18.8446C14.543 18.7212 14.7611 18.7198 14.7794 18.843C15.0088 20.3916 15.4468 20.7893 17.0959 21.0004Z" />
    <path d="M22.8703 14.4716C23.043 14.4927 23.0433 14.7908 22.8707 14.8124C20.3501 15.128 19.742 15.7315 19.4159 18.1613C19.393 18.332 19.1028 18.332 19.0798 18.1613C18.7535 15.7358 18.1445 15.1485 15.6197 14.8383C15.4472 14.8171 15.447 14.5193 15.6196 14.4977C18.1449 14.1823 18.7537 13.5797 19.08 11.1481C19.1029 10.9774 19.392 10.9775 19.4147 11.1483C19.7365 13.5749 20.3323 14.1615 22.8703 14.4716Z" />
  </>,
  'SparkleIcon'
);
