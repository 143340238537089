import { createEvmSdk } from '@infinex/evm-sdk';
import * as manifest from '@infinex/manifest';
import { type EvmSdkManifest } from '@infinex/manifest/types';

import { ETHEREUM_RPC, INFINEX_ENVIRONMENT } from '../config';

type EvmSdk = ReturnType<
  typeof createEvmSdk<EvmSdkManifest['abis'], EvmSdkManifest['chains']>
>;

export const getEvmSdk = (): {
  evmSdk: EvmSdk;
  evmSdkManifest: EvmSdkManifest;
} => {
  const defaultManifest = (() => {
    const environment = INFINEX_ENVIRONMENT;
    return {
      dev: manifest.test,
      test: manifest.test,
      staging: manifest.staging,
      prod: manifest.prod,
    }[environment];
  })();

  if (!defaultManifest) {
    throw new Error('Default manifest not found');
  }

  const evmSdkManifest: EvmSdkManifest = {
    ...defaultManifest,
    chains: {
      ...defaultManifest.chains,
      ethereum: {
        ...defaultManifest.chains.ethereum,
        config: {
          ...defaultManifest.chains.ethereum.config,
          rpcUrls: {
            default: {
              http: [ETHEREUM_RPC || ''],
            },
          },
        },
      },
    },
  };

  const evmSdk = createEvmSdk(evmSdkManifest);

  return {
    evmSdk,
    evmSdkManifest,
  };
};
