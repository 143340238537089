import { useCountdown } from '@/utils/utils';

import ClaimPatronTable from './ClaimPatronTable';
import ClaimPatronTableMobile from './ClaimPatronTableMobile';

import usePatronData from '../../../../hooks/usePatronData';

interface ClaimPatronsProps {
  dateOfNextEmission: Date;
}

export type VestingItem = {
  id: number;
  tier: string;
  total: number;
  claimed: number;
  tier_number: number;
  available_to_claim: number;
};

export default function ClaimPatrons({
  dateOfNextEmission,
}: ClaimPatronsProps) {
  const patronData = usePatronData();
  const { days, hours, minutes } = useCountdown(dateOfNextEmission);

  if (!patronData) return null;

  const { liquid, linear, locked } = patronData;

  // Calculate patron figures
  const vestingItems = [
    {
      id: 1,
      tier_number: 1,
      tier: 'liquid',
      total: liquid.total,
      claimed: liquid.total - liquid.locked - liquid.claimable,
      available_to_claim: liquid.claimable,
    },
    {
      id: 2,
      tier_number: 4,
      tier: 'linear',
      total: linear.total,
      claimed: linear.total - linear.locked - linear.claimable,
      available_to_claim: linear.claimable,
    },
    {
      id: 3,
      tier_number: 5,
      tier: 'locked',
      total: locked.total,
      claimed: locked.total - locked.locked - locked.claimable,
      available_to_claim: locked.claimable,
    },
  ] satisfies VestingItem[];

  return (
    <div className="bg-surfaceOne flex flex-col items-center gap-y-10 rounded-2xl p-8">
      {/* HEADER */}
      <div className="flex w-full flex-col items-center justify-between gap-y-6 sm:flex-row">
        <h1 className="title-lg-semibold text-slate12 leading-none">
          Claim your patrons
        </h1>
        <div className="flex items-center gap-x-3">
          <p className="body-sm-normal text-slate11">
            Next Patron unlocking in
          </p>
          <p className="title-base-medium leading-none">
            {days}D <span className="text-disabled">:</span> {hours}H{' '}
            <span className="text-disabled">:</span> {minutes}M
          </p>
        </div>
      </div>

      {/* CLAIM PATRON TABLE */}
      <div className="w-full">
        {/* Claim Patron Table displayed on >md-sized screens */}
        <ClaimPatronTable vestingItems={vestingItems} />
        {/* Claim Patron Table displayed on <md-sized screens */}
        <ClaimPatronTableMobile vestingItems={vestingItems} />
      </div>
    </div>
  );
}
