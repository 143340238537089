import { type ReactNode, Children, createContext, useMemo } from 'react';

import { type StyleProps, cn } from '../../utils';
import { EMBLEM_DIMENSIONS } from './constants';

export type EmblemGroupProps = {
  /** The children to render. */
  children: ReactNode;
  /**
   * The maximum number of emblems to render. If the number of children exceeds
   * this value a remainder count will be displayed beside the group
   * @default 3
   */
  max?: number;
  /** The size of each emblem in the group. */
  size?: keyof typeof EMBLEM_DIMENSIONS;
} & StyleProps;

/**
 * An emblem group displays a number of tokens grouped together in a stack.
 */
export const EmblemGroup = (props: EmblemGroupProps) => {
  const { children, className, max = 3, style, size = 'medium' } = props;

  const total = Children.count(children);
  const remainder = useMemo(() => Math.max(0, total - max), [total, max]);

  return (
    <EmblemGroupContext.Provider value={useMemo(() => ({ size }), [size])}>
      <div className={cn('flex items-center gap-2', className)} style={style}>
        <div className="flex items-center">
          {Children.toArray(children)
            .slice(0, max)
            .map((child, idx) => {
              const isFirst = idx === 0;

              return (
                <div
                  key={idx}
                  className={cn(
                    'relative size-[var(--size)] rounded-full',
                    '[--radius:calc(var(--size)_/_2_+_var(--stroke)_/_2)]',
                    '[--overlap:calc(var(--radius)_/_-2_+_var(--stroke))]',
                    {
                      'ms-[var(--overlap)]': !isFirst,
                      '[mask:radial-gradient(circle_var(--radius)_at_calc(var(--radius)_/_-2)_50%,_transparent_99%,_#fff_100%)]':
                        !isFirst,
                    }
                  )}
                  style={{
                    // @ts-expect-error — vars are valid CSS properties
                    '--size': `${EMBLEM_DIMENSIONS[size].size}px`,
                    '--stroke': 'calc(var(--size) / 16)',
                  }}
                >
                  {child}
                </div>
              );
            })}
        </div>

        {remainder > 0 && (
          <div
            className={cn('text-secondary', {
              'body-xs-medium': size === 'small' || size === 'xsmall',
              'body-sm-medium': size === 'medium',
              'body-base-medium': size === 'large' || size === 'xlarge',
            })}
          >
            +{remainder}
          </div>
        )}
      </div>
    </EmblemGroupContext.Provider>
  );
};

export const EmblemGroupContext = createContext<{
  size: keyof typeof EMBLEM_DIMENSIONS;
} | null>(null);
