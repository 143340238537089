import { ethers, utils } from 'ethers';
import { base58 } from 'ethers/lib/utils';
import { uniq } from 'lodash';
import { DateTime, ToRelativeOptions } from 'luxon';
import { useEffect, useState } from 'react';

export function isPositiveNumeric(value: string) {
  return (
    value === '' ||
    (/^[0-9]*\.?[0-9]{0,6}$/.test(value) && parseFloat(value) < 100)
  );
}

export const formatTimeToHHMMSS = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

// Checks if a numString is empty or zero
export function isZeroNumstring(str: string) {
  return str === '' || str === '.' || /^[0.]+$/.test(str);
}

export function validDecimalInput(char: string) {
  const validChars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '.',
    'Backspace',
    'Delete',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
  ];
  return validChars.includes(char);
}

export const fetchHHMMSS = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return {
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: seconds.toString().padStart(2, '0'),
  };
};

export const relativeTime = (
  timestamp: string,
  relativeOptions: ToRelativeOptions = {}
) => {
  const date = new Date(timestamp).getTime();
  const now = Date.now();
  const oneDay = 24 * 60 * 60 * 1000;

  if (now - date < oneDay) {
    return DateTime.fromMillis(date).toRelative(relativeOptions);
  } else {
    return DateTime.fromMillis(date).toFormat('dd-MM-yyyy');
  }
};

export function truncateAddress(address: string, length: number = 10): string {
  if (address.startsWith('0x')) {
    const start = address.slice(2, length / 2 + 2);
    const end = address.slice(-length / 2);
    return `0x${start}…${end}`;
  } else {
    const start = address.slice(0, length / 2);
    const end = address.slice(-length / 2);
    return `${start}…${end}`;
  }
}

// Check if address has a contract deployed to it
export async function isContract(address: string, rpcUrl: string) {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const code = await provider.getCode(address);
  return code !== '0x';
}

export interface Countdown {
  totalSeconds: number;
  days: number;
  hours: number;
  totalHours: number;
  minutes: number;
  seconds: number;
  isPast: boolean;
  /** Use when only countdown of days should display. Uses `ceil` instead of `floor`. */
  daysCeil: number;
}

export const prioritySort = (
  array: string[],
  priorityList: string[]
): string[] => {
  return array.sort((a, b) => {
    const indexA = priorityList.indexOf(a);
    const indexB = priorityList.indexOf(b);

    if (indexA === -1 && indexB === -1) {
      // Neither element is in the priority list, sort them normally
      return a.localeCompare(b);
    }

    if (indexA === -1) {
      // A is not in the priority list, B should come first
      return 1;
    }

    if (indexB === -1) {
      // B is not in the priority list, A should come first
      return -1;
    }

    // Both elements are in the priority list, sort them based on their indices
    return indexA - indexB;
  });
};

export const calculateTimeRemaining = (targetDate: Date) => {
  const now = new Date();
  const difference = targetDate.getTime() - now.getTime();

  // If the difference is less than or equal to zero, the countdown is over.
  if (difference <= 0) {
    return {
      totalSeconds: 0,
      days: 0,
      daysCeil: 0,
      hours: 0,
      totalHours: 0,
      minutes: 0,
      seconds: 0,
      isPast: true,
    };
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const daysCeil = Math.ceil(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const totalHours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return {
    totalSeconds: difference / 1000,
    days,
    daysCeil,
    hours,
    totalHours,
    minutes,
    seconds,
    isPast: false,
  };
};

export const useCountdown = (targetDate: Date): Countdown => {
  // Calculate the time remaining until the target date.

  const [timeRemaining, setTimeRemaining] = useState<Countdown>(
    calculateTimeRemaining(targetDate)
  );

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => window.clearInterval(intervalId);
  }, [targetDate]);

  return timeRemaining;
};

export const useRequestId = () => {
  const [requestId, setRequestId] = useState<string>(crypto.randomUUID());
  const reset = () => setRequestId(crypto.randomUUID());
  return { requestId, resetRequestId: reset };
};

export const formatNumber = (value: number) => {
  return value.toLocaleString('en-US', {
    useGrouping: true,
    maximumFractionDigits: 2,
  });
};
