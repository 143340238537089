import { useUserStore } from '@/stores';

import {
  LINEAR_VESTING_START_DATE,
  LOCKED_VESTING_START_DATE,
} from '../common';

// Calculates the date of the next patron unlock
export default function usePatronCountdown() {
  const vestingSchedule = useUserStore((state) => state.vestingSchedule);

  // Data not fully loaded
  if (!vestingSchedule) return null;

  // Get the schedule data for linear (4), and locked (5)
  const scheduleData = ([4, 5] as const).map((index) => vestingSchedule[index]);

  // Data not fully loaded
  if (
    !scheduleData.every((data) =>
      Object.values(data).some((value) => value !== null)
    )
  ) {
    return null;
  }

  const [linear, locked] = scheduleData;

  const dateOfNextLinearEmission = calculateDateOfNextEmission({
    vestingStartDate: LINEAR_VESTING_START_DATE,
    totalPatrons: linear.total,
    tierType: 'linear',
  });

  const dateOfNextLockedEmission = calculateDateOfNextEmission({
    vestingStartDate: LOCKED_VESTING_START_DATE,
    totalPatrons: locked.total,
    tierType: 'locked',
  });

  return {
    dateOfNextEmission: getEarliestDate(
      dateOfNextLinearEmission,
      dateOfNextLockedEmission
    ),
  };
}

function getEarliestDate(date1: Date, date2: Date) {
  if (isNaN(date1.getTime())) return date2;
  if (isNaN(date2.getTime())) return date1;
  return date1 < date2 ? date1 : date2;
}

function calculateDateOfNextEmission({
  vestingStartDate,
  totalPatrons,
  tierType,
}: {
  vestingStartDate: Date;
  totalPatrons: number;
  tierType: 'linear' | 'locked';
}) {
  const daysInVestingPeriod = tierType === 'linear' ? 365 : 365 * 2;

  // Calculate the rate of patron emission per second
  const patronsPerSecond = totalPatrons / (daysInVestingPeriod * 24 * 60 * 60);

  // Calculate how many seconds it takes to emit one patron
  const secondsPerPatron = 1 / patronsPerSecond;

  const currentDate = new Date();

  // Calculate the number of seconds elapsed since the start of vesting
  const elapsedSeconds =
    (currentDate.getTime() - vestingStartDate.getTime()) / 1000;

  // Calculate the time of the last patron emission
  const lastEmissionSeconds = Math.max(
    0,
    Math.floor(elapsedSeconds / secondsPerPatron) * secondsPerPatron
  );

  // Calculate the time of the next patron emission
  const nextEmissionSeconds = lastEmissionSeconds + secondsPerPatron;

  // Calculate the date of the next patron emission
  const dateOfNextEmission = new Date(
    vestingStartDate.getTime() + nextEmissionSeconds * 1000
  );

  return dateOfNextEmission;
}
