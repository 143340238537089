import { useResizeObserver } from '@react-aria/utils';
import { AriaLabelingProps, DOMProps } from '@react-types/shared';
import { forwardRef, useCallback, useContext, useState } from 'react';
import { useObjectRef } from 'react-aria';
import {
  type Key,
  type ToggleButtonProps,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-aria-components';

import { type StyleProps, cn, useIsSmallScreen } from '../../utils';
import { SurfaceContext } from '../layout';

export type SegmentedControlProps = {
  /**
   * The alignment of the segment buttons within the control.
   *
   * @default start
   */
  align?: 'start' | 'justify';
  /** The labels for the control’s segment buttons, in order. */
  items: Key[];
  /** Whether all items are disabled. */
  isDisabled?: boolean;
  /** The disabled segments of the control, if any. */
  disabledKeys?: Key[];
  /** The initial selected key in the collection (uncontrolled). */
  defaultSelectedKey?: Key;
  /** The currently selected key in the collection (controlled). */
  selectedKey?: Key;
  /** Handler that is called when the selection changes. */
  onSelectionChange?: (key: Key) => void;
} & AriaLabelingProps &
  DOMProps &
  StyleProps;

/**
 * A navigation device that allows the user to switch between different
 * views over the same dataset.
 */
export const SegmentedControl = forwardRef<
  HTMLDivElement,
  SegmentedControlProps
>(function SegmentedControl(props, forwardedRef) {
  const {
    align = 'start',
    className,
    defaultSelectedKey,
    disabledKeys,
    items,
    onSelectionChange: onSelectionChangeProp,
    selectedKey,
    ...otherProps
  } = props;

  const isOnSurface = useContext(SurfaceContext);
  const [selectedRect, setSelectedRect] = useState<SimpleRect | null>(null);

  const domRef = useObjectRef(forwardedRef);
  const updateSelectedRect = useCallback(() => {
    const groupElement = domRef.current;
    if (!groupElement) return;
    const element = groupElement.querySelector('button[aria-checked="true"]');
    setSelectedRect(element ? getIndicatorRect(element, groupElement) : null);
  }, [domRef]);

  // keep the selected item indicator in sync with the user’s viewport
  useResizeObserver({ ref: domRef, onResize: updateSelectedRect });

  const onSelectionChange = useCallback(
    (keys: Set<Key>) => {
      // simplify opinionated usage: always selectionMode="single"
      const key = keys.values().next().value;
      if (key) onSelectionChangeProp?.(key);
      // wait a moment for the DOM to update
      setTimeout(updateSelectedRect, 100);
    },
    [onSelectionChangeProp, updateSelectedRect]
  );

  return (
    <ToggleButtonGroup
      ref={domRef}
      disallowEmptySelection
      selectionMode="single"
      onSelectionChange={onSelectionChange}
      selectedKeys={selectedKey ? [selectedKey] : undefined}
      defaultSelectedKeys={
        defaultSelectedKey ? [defaultSelectedKey] : undefined
      }
      className={cn(
        'bg-canvas relative grid grid-flow-col gap-[var(--gap)] rounded-full p-[var(--gutter)]',
        'disabled:pointer-events-none', // bug w/rac-tailwind?
        '[--gap:4px]',
        '[--gutter:4px]',
        {
          'bg-canvasDark': isOnSurface,
          'w-fit': align === 'start',
          'auto-cols-fr': align === 'justify',
        },
        className
      )}
      {...otherProps}
    >
      {/* indicator element */}
      {selectedRect && (
        <div
          className={cn(
            'bg-canvasDark pointer-events-none absolute inset-0 rounded-full transition-all',
            {
              'bg-neutralSelected': isOnSurface,
            }
          )}
          style={{
            left: 0,
            top: selectedRect.top,
            width: selectedRect.width,
            height: selectedRect.height,
            transform: `translateX(${selectedRect.left}px)`,
          }}
        />
      )}

      {items.map((item) => (
        <SegmentButton
          key={item}
          id={item}
          isDisabled={disabledKeys?.includes(item)}
        >
          {item}
        </SegmentButton>
      ))}
    </ToggleButtonGroup>
  );
});

// Utils
// -----------------------------------------------------------------------------

type SimpleRect = {
  left: number;
  top: number;
  width: number;
  height: number;
};

function getIndicatorRect(element: Element, parent: Element): SimpleRect {
  const rect = element.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  return {
    left: rect.left - parentRect.left,
    top: rect.top - parentRect.top,
    width: rect.width,
    height: rect.height,
  };
}

// Styled components
// -----------------------------------------------------------------------------

const SegmentButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function SegmentButton(props, ref) {
    const { children, className, ...otherProps } = props;

    const isSmallScreen = useIsSmallScreen();

    return (
      <ToggleButton
        ref={ref}
        className={cn(
          'text-secondary body-sm-semibold relative select-none rounded-full px-6 outline-none transition-colors',
          'hover:bg-neutralHover hover:text-default',
          'pressed:bg-neutralActive',
          'focus-visible:outline-accent',
          'selected:bg-none selected:text-default',
          'disabled:text-disabled',
          // extend the hit area
          'after:absolute after:inset-x-[calc(var(--gap)/-2)] after:inset-y-[calc(var(--gutter)*-1)]',
          {
            'h-7': !isSmallScreen,
            'h-9': isSmallScreen,
          },
          className
        )}
        {...otherProps}
      >
        {children}
      </ToggleButton>
    );
  }
);
